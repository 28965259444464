.home {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.base {
  width: max(900px, min(calc((991px - 100vw) * 9999), 100vw));
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100vw;
  font-size: max(10px, min(calc((100vw - 991px) * 9999), 15px));
  overflow: hidden;
}
.base.value {
  flex-direction: column;
}
.order-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-search-input {
  width: 75%;
  border: 2px solid lightgray;
  height: max(25px, min(calc((100vw - 991px) * 9999), 40px));
}
.base-search-input:focus {
  border: 2px solid #64bfff;
}
.btn-search-home-container {
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.btn-search-home {
  background-color: #009250;
  width: max(60px, min(calc((100vw - 991px) * 9999), 150px));
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  padding-right: max(2px, min(calc((100vw - 991px) * 9999), 5px));
  padding-left: max(2px, min(calc((100vw - 991px) * 9999), 5px));
  border-radius: 5px;
  cursor: pointer;
}
.btn-search-home:hover {
  background-color: #005b40;
}
