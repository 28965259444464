.confirm-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  width: 100px;
  height: 35px;
  cursor: pointer;
  z-index: inherit;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 2px 2px 2px lightgray, -2px 2px 2px lightgray, 2px -2px 2px lightgray,
    -2px -2px 2px lightgray;
}
.confirm-button:focus {
  border: none;
  outline: none;
}
.confirm-button:active {
  transform: translateY(2px);
}
